/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
// import ChartistGraph from "react-chartist";
import {
  // Grid,
  Row,
  Col,
  Table,
} from "react-bootstrap";
// import { Grid } from "react-bootstrap/grid";

import { Card } from "../Card/Card.jsx";
// import { StatsCard } from "components/StatsCard/StatsCard.jsx";
// import { Tasks } from "components/Tasks/Tasks.jsx";

// import {
//   //  HOST,
//   // MISC,
//   SUBS_LIST,
// } from "../network/ApiPaths";
// import Uri from "../network/Uri";
// import getResponse from "../network/GetDataResponse";
// const userCheck = window.localStorage.getItem("access");
// let storeDet = JSON.parse(userCheck);
// let storeIdValue;
// if (storeDet !== null) {
//   storeIdValue = storeDet.storeId;
// } else {
//   storeIdValue = "";
// }
class Subscriptions extends Component {
  constructor(props) {
    super();
    this.state = {
      subscriptions: [],
    };
  }
  render() {
    // const { subscriptions } = this.state;
    return (
      <div className="content">
        {/* <Grid fluid> */}
        <Row>
          <Col md={12}>
            <Card
              title="Striped Table with Hover"
              category="Here is a subtitle for this table"
              ctTableFullWidth
              ctTableResponsive
              content={
                <>
                  <div className="header">
                    <h4 className="title">
                      <div id="numbers">
                        <p>Subscriptions List Not for Godavari Foods</p>
                      </div>
                    </h4>
                  </div>
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th>Status</th>
                        <th>itemId</th>
                        <th>start</th>
                        <th>end</th>
                        <th>frequency</th>
                        <th>userId</th>
                        <th>next_schedule.</th>
                        <th>last_schedule_date</th>
                        <th>storeId</th>
                        {/* <th>createdOn</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {/* {subscriptions !== []
                          ? subscriptions.map((p, index) => {
                              return (
                                <tr key={index}>
                                  <td>{p.status}</td>
                                  <td>{p.menu_item_id}</td>
                                  <td>{p.start_date}</td>
                                  <td>{p.end_date}</td>
                                  <td>{p.frequency}</td>
                                  <td>{p.user_id}</td>
                                  <td>{p.next_schedule}</td>
                                  <td>{p.last_schedule_date}</td>
                                  <td>{p.store_id}</td>
                                </tr>
                              );
                            })
                          : null} */}
                    </tbody>
                  </Table>
                </>
              }
            />
          </Col>
        </Row>
        {/* </Grid> */}
      </div>
    );
  }
  // async componentDidMount() {
  //   var apiPath4 = new Uri(SUBS_LIST);
  //   // .addQueryParam("store_id", storeIdValue);
  //   getResponse(
  //     apiPath4,
  //     (onSuccessResponse) => {
  //       this.setState({
  //         subscriptions: onSuccessResponse.result,
  //       });
  //     },
  //     (onError) => {
  //       alert(onError.message);
  //     }
  //   );
  // }
}

export default Subscriptions;
