import React from "react";

const Footer = () => (
  <footer className="main-footer">
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-6">
          <p>amaze-deliver &copy; 2020-2022</p>
        </div>
        <div className="col-sm-6 text-right"></div>
      </div>
    </div>
  </footer>
);

export default Footer;
